import posthogJs from "posthog-js";
import { useEffect, useState } from "react";

type FeatureFlags<T extends string[]> = {
  [K in T[number]]: boolean | undefined;
};

const getFeatureFlags = <T extends string[]>(ffs: T): FeatureFlags<T> => {
  const flags = {} as FeatureFlags<T>;

  ffs.forEach((flag: T[number]) => {
    flags[flag] = posthogJs.isFeatureEnabled(flag);
  });

  return flags;
};

export const useFeatureFlags = <T extends string[]>(
  ...featureFlags: T
): FeatureFlags<T> => {
  const [flags, setFlags] = useState<FeatureFlags<T>>({} as FeatureFlags<T>);

  useEffect(
    () => {
      const remove = posthogJs.onFeatureFlags(function () {
        setFlags(getFeatureFlags(featureFlags));
      });

      return remove;
    },
    /**
     * This can get caught in an infinite loop if you include feature flags
     * and since it should be static anyway we can leave this as an empty array
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return flags;
};
